import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-product-list"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    ref: "handleGetSum"
  }), _c('MainTabs', {
    attrs: {
      "isHover": _vm.isHover,
      "activeIndex": _vm.tabActive
    },
    on: {
      "getCurrentData": _vm.onClicktab
    }
  }), _c('div', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "infinite-scroll-disabled": "busy",
      "infinite-scroll-distance": "10"
    }
  }, [_c('div', {
    staticClass: "search-result-wrap"
  }, [_c('div', {
    staticClass: "result-order-top"
  }, [_c('ul', {
    staticClass: "result-order-wrap"
  }, [_c('li', {
    staticClass: "acti-zt"
  }, [_vm._v("积分专区")]), _vm._l(_vm.orderTypeList, function (item, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.changeOrder(index);
        }
      }
    }, [_c('span', {
      class: {
        active: index === _vm.activeIdx
      }
    }, [_vm._v("切换" + _vm._s(item))])]);
  })], 2), _c('div', {
    staticClass: "search-box"
  }, [_c('img', {
    attrs: {
      "src": require("@views/product/product-list/images/icon_search.png"),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productName,
      expression: "productName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "本专区请输入商品名称搜索"
    },
    domProps: {
      "value": _vm.productName
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.toEnter.apply(null, arguments);
      },
      "blur": _vm.onSearchBlur,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.productName = $event.target.value;
      }
    }
  })])]), !_vm.isListMode ? _c('ProductList', {
    attrs: {
      "productList": _vm.productList
    },
    on: {
      "handelImg": _vm.handelImg,
      "getGoodsList": _vm.getactivityList
    }
  }) : _c('div', [_c('ListShow', {
    attrs: {
      "productList": _vm.productList,
      "isExternal": _vm.isExternal
    },
    on: {
      "toAddCart": _vm.toAddCart
    }
  })], 1), _vm.productList.length ? _c('div', {
    staticClass: "bottom-more-style"
  }, [_vm._v(" " + _vm._s(_vm.bottomTitle) + " ")]) : _vm._e()], 1)]), _vm.totalNum === 0 ? [_vm._m(0)] : _vm._e(), _c('PageFooter'), Number(_vm.cartInfo.allTotalMoney) > 0 ? _c('div', {
    staticClass: "cart-activity1"
  }, [_c('div', {
    staticClass: "cart-activity-l1"
  }, [_c('div', {
    staticClass: "cart-activity-l-t1"
  }, [[_vm._v(" 当前订单总额" + _vm._s(_vm.cartInfo.allTotalMoney) + "元， ")], Number(_vm.cartInfo.allTotalMoney) > 0 && Number(_vm.cartInfo.isMax) < 0 ? [_vm._v(" 再凑" + _vm._s(_vm.cartInfo.amountGap) + "元， ")] : _vm._e(), Number(_vm.cartInfo.isMax) > -1 ? [_vm._v(" 积分值为" + _vm._s(_vm.cartInfo.proportion) + "， ")] : _vm._e(), Number(_vm.cartInfo.isMax) > -1 ? [_vm._v(" 可获得奖励" + _vm._s(_vm.cartInfo.giftAmount) + "元。次日到账。 ")] : _vm._e(), Number(_vm.cartInfo.allTotalMoney) > 0 && Number(_vm.cartInfo.isMax) < 0 ? [_vm._v(" 可参与红包返利活动 ")] : _vm._e()], 2)]), Number(_vm.cartInfo.isMax) > -1 ? _c('div', {
    staticClass: "cart-activity-r1",
    on: {
      "click": _vm.goMakebill
    }
  }, [_vm._v(" 立即下单 ")]) : _vm._e()]) : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "no-result-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("./images/no-result.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };